import { css } from 'styled-components';

export const buttonStylesMixin = css`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  line-height: 18px;
  font-size: 18px;
  padding: 19px 24px;
  text-align: center;
  border-radius: 8px;
  height: 56px;
  max-width: ${({ theme }) => `calc(${theme.maxWidth} - 2 * ${theme.pagePadding})`};

  &.small {
    padding: 11px;
    height: 32px;
    min-width: 100px;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.5px;

    &.secondary {
      background-color: ${({ theme }) => theme.palette.violet[50]};
      color: ${({ theme }) => theme.palette.violet[400]};
      &:active {
        background-color: ${({ theme }) => theme.palette.violet[100]};
        color: ${({ theme }) => theme.palette.violet[400]};
      }
      &.active {
        background-color: ${({ theme }) => theme.palette.violet[100]};
        color: ${({ theme }) => theme.palette.violet[400]};
      }
      @media only screen and (${({ theme }) => theme.sizes.tablet}) {
        &:hover {
          background-color: ${({ theme }) => theme.palette.violet[100]};
          color: ${({ theme }) => theme.palette.violet[400]};
        }
      }
      &.disabled {
        background-color: ${({ theme }) => theme.palette.black[200]};
        color: ${({ theme }) => theme.palette.black[500]};
        cursor: not-allowed;
      }
      &:disabled {
        background-color: ${({ theme }) => theme.palette.black[200]};
        color: ${({ theme }) => theme.palette.black[500]};
        cursor: not-allowed;
      }
    }
    &.tertiary {
      background-color: ${({ theme }) => theme.palette.black[50]};
      color: ${({ theme }) => theme.palette.black[900]};
      &:active {
        background-color: ${({ theme }) => theme.palette.black[200]};
        color: ${({ theme }) => theme.palette.black[900]};
      }
      &.active {
        background-color: ${({ theme }) => theme.palette.black[200]};
        color: ${({ theme }) => theme.palette.black[900]};
      }
      @media only screen and (${({ theme }) => theme.sizes.tablet}) {
        &:hover {
          background-color: ${({ theme }) => theme.palette.black[200]};
          color: ${({ theme }) => theme.palette.black[900]};
        }
      }
      &.disabled {
        background-color: ${({ theme }) => theme.palette.black[200]};
        color: ${({ theme }) => theme.palette.black[500]};
        cursor: not-allowed;
      }
      &:disabled {
        background-color: ${({ theme }) => theme.palette.black[200]};
        color: ${({ theme }) => theme.palette.black[500]};
        cursor: not-allowed;
      }
    }
    &.quaternary {
      background-color: rgba(255, 255, 255, 0.8);
      color: ${({ theme }) => theme.palette.black[900]};
      &:active {
        background-color: ${({ theme }) => theme.palette.white};
        color: ${({ theme }) => theme.palette.black[900]};
      }
      &.active {
        background-color: ${({ theme }) => theme.palette.white};
        color: ${({ theme }) => theme.palette.black[900]};
      }
      @media only screen and (${({ theme }) => theme.sizes.tablet}) {
        &:hover {
          background-color: ${({ theme }) => theme.palette.white};
          color: ${({ theme }) => theme.palette.black[900]};
        }
      }
      &.disabled {
        background-color: rgba(255, 255, 255, 0.8);
        color: ${({ theme }) => theme.palette.black[600]};
        cursor: not-allowed;
      }
      &:disabled {
        background-color: rgba(255, 255, 255, 0.8);
        color: ${({ theme }) => theme.palette.black[600]};
        cursor: not-allowed;
      }
    }
    &.login {
      color: ${({ theme }) => theme.palette.black[900]};
      background-color: ${({ theme }) => theme.palette.white};
      box-shadow: 0 0 0 1.5px ${({ theme }) => theme.palette.white};
    }
  }

  &.bottom-sheet {
    height: 32px;
    gap: 12px;
    font-size: 16px;
    font-weight: 400;
    justify-content: flex-start;
    padding: 0;

    .icon-container {
      padding: 6px;
      background-color: ${({ theme }) => theme.palette.black[50]};
      border-radius: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  &.fixed {
    position: fixed;
    width: ${({ theme }) => `calc(100% - 2 * ${theme.pagePadding})`};
    bottom: 16px;
    left: 50%;
    transform: translateX(-50%);
  }

  &.primary {
    background-color: ${({ theme }) => theme.palette.violet[400]};
    color: ${({ theme }) => theme.palette.white};

    &.active {
      background-color: ${({ theme }) => theme.palette.violet[100]};
    }
    &:active {
      background-color: ${({ theme }) => theme.palette.violet[100]};
    }
    @media only screen and (${({ theme }) => theme.sizes.tablet}) {
      &:hover {
        background-color: ${({ theme }) => theme.palette.violet[100]};
      }
    }
    &:disabled {
      background-color: ${({ theme }) => theme.palette.black[200]};
      color: ${({ theme }) => theme.palette.black[500]};
      cursor: not-allowed;
    }
    &.disabled {
      background-color: ${({ theme }) => theme.palette.black[200]};
      color: ${({ theme }) => theme.palette.black[500]};
      cursor: not-allowed;
    }
  }

  &.secondary {
    background-color: transparent;
    color: ${({ theme }) => theme.palette.violet[400]};
    &:active {
      color: ${({ theme }) => theme.palette.violet[100]};
    }
    &.active {
      color: ${({ theme }) => theme.palette.violet[100]};
    }
    &.disabled {
      background-color: ${({ theme }) => theme.palette.black[200]};
      color: ${({ theme }) => theme.palette.black[500]};
      cursor: not-allowed;
    }
    &:disabled {
      background-color: ${({ theme }) => theme.palette.black[200]};
      color: ${({ theme }) => theme.palette.black[500]};
      cursor: not-allowed;
    }
  }

  &.ghost {
    background-color: ${({ theme }) => theme.palette.white};
    color: ${({ theme }) => theme.palette.violet[400]};
    box-shadow: 0 0 0 1.5px ${({ theme }) => theme.palette.violet[400]};
    &:active {
      color: ${({ theme }) => theme.palette.violet[100]};
      box-shadow: 0 0 0 1px ${({ theme }) => theme.palette.violet[100]};
    }
    &.active {
      color: ${({ theme }) => theme.palette.violet[100]};
      box-shadow: 0 0 0 1px ${({ theme }) => theme.palette.violet[100]};
    }
    @media only screen and (${({ theme }) => theme.sizes.tablet}) {
      &:hover {
        color: ${({ theme }) => theme.palette.violet[100]};
        box-shadow: 0 0 0 1px ${({ theme }) => theme.palette.violet[100]};
      }
    }
    &.disabled {
      box-shadow: 0 0 0 1px ${({ theme }) => theme.palette.black[500]};
      color: ${({ theme }) => theme.palette.black[500]};
      cursor: not-allowed;
    }
    &:disabled {
      background-color: ${({ theme }) => theme.palette.black[200]};
      color: ${({ theme }) => theme.palette.black[500]};
      cursor: not-allowed;
    }
  }

  &.noPadding {
    padding: 0;
    height: auto;
  }
`;
