import jwtDecode from 'jwt-decode';

export interface DecodedTokenProps {
  exp: number;
  sub: string;
}

export const decodeToken = (token: string) => jwtDecode(token);

export const isExpired = (token: string) => {
  const { exp } = decodeToken(token) as DecodedTokenProps;
  return new Date().getTime() / 1000 + 180 >= exp;
};
