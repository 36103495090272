import Modal from 'components/Modal';
import Error from 'next/error';
import { useRouter } from 'next/router';
import NProgress from 'nprogress';
import { useEffect, useState } from 'react';
import { StyledLoadingContent, StyledLoadingOverlay } from './PageLoading.styled';

interface LoadingProps {}

const PageLoading: React.FC<LoadingProps> = ({}) => {
  const router = useRouter();

  const [loading, setLoading] = useState(false);
  NProgress.configure({ showSpinner: false, minimum: 0.8, speed: 900 });
  useEffect(() => {
    const handleStart = () => {
      setLoading(true);
      NProgress.start();
    };

    const handleComplete = () => {
      setLoading(false);
      NProgress.done();
    };

    const handleError = (err: Error) => {
      if (err) {
        setLoading(false);
        NProgress.done();
      }
    };

    router.events.on('routeChangeStart', handleStart);
    router.events.on('routeChangeComplete', handleComplete);
    router.events.on('routeChangeError', handleError);

    return () => {
      router.events.off('routeChangeStart', handleStart);
      router.events.off('routeChangeComplete', handleComplete);
      router.events.off('routeChangeError', handleComplete);
    };
  });

  return (
    <Modal isOpen={loading} OverlayStyle={StyledLoadingOverlay} ModalStyle={StyledLoadingContent}>
      <></>
    </Modal>
  );
};

export default PageLoading;
