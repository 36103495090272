import React, { useContext, useState, ReactNode } from 'react';

export interface UIContextInterface {
  isMuted: boolean;
  isPromptOpen: boolean;
  toggleMute: () => void;
  showAppPrompt: (value: boolean) => void;
}

const AuthContext = React.createContext<UIContextInterface>({
  isMuted: false,
  isPromptOpen: false,
  toggleMute: () => true,
  showAppPrompt: (value: boolean) => true,
});

export function useUIContext() {
  return useContext(AuthContext);
}

export function UIContextProvider({ children }: { children: ReactNode | ReactNode[] }) {
  const [isMuted, setIsMuted] = useState(false);
  const [isPromptOpen, setIsPromptOpen] = useState(false);

  function toggleMute() {
    setIsMuted((prevValue) => !prevValue);
  }

  function showAppPrompt(value: boolean) {
    setIsPromptOpen(value);
  }

  const value: UIContextInterface = {
    isMuted,
    isPromptOpen,
    toggleMute,
    showAppPrompt,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}
