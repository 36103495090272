export const scopes = {
  machineToken: [
    'identity.api',
    'charities.api',
    'payments.api',
    'users.api',
    'graphs.api',
    'content.api',
    'social.api',
    'flows.api',
  ],
  refreshToken: ['graphs.api', 'users.api', 'social.api', 'content.api', 'social.api'],
  accessToken: ['graphs.api', 'users.api', 'social.api', 'content.api', 'social.api'],
};
