import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../store';

interface authState {
  accessToken: string | null;
  machineToken: string | null;
  refreshToken: string | null;
  isUserLoggedIn: boolean;
  isLoginLoading: boolean;
}

const initialState = {
  accessToken: null,
  machineToken: null,
  refreshToken: null,
} as authState;

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setAccessToken(state, action) {
      state.accessToken = action.payload;
    },
    setMachineToken(state, action) {
      state.machineToken = action.payload;
    },
    setRefreshToken(state, action) {
      state.refreshToken = action.payload;
    },
  },
});

export const { setAccessToken, setMachineToken, setRefreshToken } = authSlice.actions;

export default authSlice.reducer;

export const selectAccessToken = (state: RootState) => state.auth.accessToken;
export const selectRefreshToken = (state: RootState) => state.auth.refreshToken;
export const selectMachineToken = (state: RootState) => state.auth.machineToken;
