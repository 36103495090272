import styled from 'styled-components';

export const StyledModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  background: rgba(0, 0, 0, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100%;
  z-index: 99999;
`;

export const StyledAnimatedModalContent = styled.div`
  &.ReactModal__Content {
    transform: translateY(100%);
    transition: transform 400ms ease-in-out;
  }

  &.ReactModal__Content--after-open {
    animation: slideUp 400ms forwards;
  }

  &.ReactModal__Content--before-close {
    animation: slideDown 400ms forwards;
  }

  /* Slide Up Animation */
  @keyframes slideUp {
    from {
      transform: translateY(100%);
    }
    to {
      transform: translateY(0);
    }
  }

  /* Slide Down Animation */
  @keyframes slideDown {
    from {
      transform: translateY(0);
    }
    to {
      transform: translateY(100%);
    }
  }
`;
