import styled from 'styled-components';

export const StyledLoadingContent = styled.div`
  position: relative;
  background: transparent;
  border: none;
  outline: none;
  width: 100vw;
  max-width: 500px;
  display: flex;
  justify-content: flex-end;
  padding: 16px;
`;
export const StyledLoadingOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  background: transparent;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  width: 100vw;
  max-width: 500px;
  height: 100%;
  z-index: 99999;

  @media (min-width: 500px) {
    transform: translateX(-50%);
  }
`;
