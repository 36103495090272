import 'assets/styles/fonts.css';
import { GlobalStyles } from 'assets/styles/global.styled';
import 'assets/styles/nprogress.css';
import { defaultTheme } from 'assets/styles/theme';
import PageLoading from 'components/PageLoading/PageLoading';
import { UIContextProvider } from 'contexts/UIContext';
import useLanguage from 'hooks/useLanguage';
import { SessionProvider } from 'next-auth/react';
import type { AppProps } from 'next/app';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { Provider } from 'react-redux';
import { store } from 'store/store';
import { ThemeProvider } from 'styled-components';
import { setupTransifex } from 'utils/setUpTransifex';

function App({ Component, pageProps: { session, ...restProps } }: AppProps) {
  const router = useRouter();

  setupTransifex();

  useEffect(() => {
    const handleBeforeHistoryChange = (url: string) => {
      // Check if the new URL is the same as the current URL
      if (url === router.asPath) {
        // Prevent the navigation by calling `router.events.emit()` with the `beforeHistoryChange` event
        router.events.emit('beforeHistoryChange');
        return false; // Return false to prevent the navigation
      }
      return true; // Continue with the navigation
    };

    // Listen for before history changes
    router.events.on('beforeHistoryChange', handleBeforeHistoryChange);

    // Clean up the event listener
    return () => {
      router.events.off('beforeHistoryChange', handleBeforeHistoryChange);
    };
  }, [router]);

  /*hotfix for an IOS chromium known bug related with login forms
    and autocompletes. Its' not affecting users and can be ignored,
    but it is suggested to do that on the js init to prevent
    the error logging. Hope it will fix error logging.
  */
  useEffect(() => {
    if (!(window as any).__gCrWeb) (window as any)['__gCrWeb'] = {};
  }, []);

  const { isLocalizationLoading } = useLanguage();

  return (
    <Provider store={store}>
      <SessionProvider session={session} refetchInterval={240}>
        <ThemeProvider theme={defaultTheme}>
          <UIContextProvider>
            <GlobalStyles />
            <Head>
              <link rel="icon" href="/8favicon-32.png" sizes="32x32" />
              <link rel="icon" href="/8favicon-128.png" sizes="128x128" />
              <link rel="icon" href="/8favicon-180.png" sizes="180x180" />
              <link rel="icon" href="/8favicon-192.png" sizes="192x192" />
              <title>WeAre8</title>
            </Head>
            {/* {isLocalizationLoading ? <Loader /> : <Component {...pageProps} />} */}
            <Component {...restProps} />
            <PageLoading />
          </UIContextProvider>
        </ThemeProvider>
      </SessionProvider>
    </Provider>
  );
}

export default App;
