import {
  BaseQueryFn,
  createApi,
  FetchArgs,
  fetchBaseQuery,
  FetchBaseQueryError,
  retry,
} from '@reduxjs/toolkit/query/react';
import { RootState } from 'store/store';
import { handleReauth } from 'utils/reauthHandling';

type FetchArgsWithAccessToken = { withAccessToken: boolean } & FetchArgs;
type FetchArgsWithoutAuth = { withoutAuth: boolean } & FetchArgs;

// Create our baseQuery instance
const baseQuery = retry(
  fetchBaseQuery({
    prepareHeaders: async (headers, { getState }) => {
      // By default, if we have a token in the store, let's use that for authenticated requests
      const machineToken = (getState() as RootState).auth.machineToken;

      if (machineToken) {
        headers.set('Authorization', `Bearer ${machineToken}`);
      }

      return headers;
    },
  }),
  { maxRetries: 0 }
);

const baseQueryWithAuth = retry(
  fetchBaseQuery({
    prepareHeaders: async (headers, { getState }) => {
      // By default, if we have a token in the store, let's use that for authenticated requests
      const accessToken = (getState() as RootState).auth.accessToken;

      if (accessToken) {
        headers.set('Authorization', `Bearer ${accessToken}`);
      }

      return headers;
    },
  }),
  { maxRetries: 0 }
);

const baseQueryWithoutAuth = retry(
  fetchBaseQuery({
    prepareHeaders: (headers) => headers,
  }),
  { maxRetries: 0 }
);

const baseQueryWithInterceptor: BaseQueryFn<
  string | FetchArgs | FetchArgsWithAccessToken | FetchArgsWithoutAuth,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  try {
    const withAccessToken = (args as FetchArgsWithAccessToken).withAccessToken;
    const withoutAuth = (args as FetchArgsWithoutAuth).withoutAuth;

    let result;

    if (withoutAuth) result = baseQueryWithoutAuth(args, api, extraOptions);
    else if (withAccessToken) result = await baseQueryWithAuth(args, api, extraOptions);
    else {
      await handleReauth({ args, api, extraOptions, baseQuery });
      result = await baseQuery(args, api, extraOptions);
    }

    return result;
  } catch (error) {
    throw error;
  }
};

/**
 * Create a base API to inject endpoints into elsewhere.
 * Components using this API should import from the injected site,
 * in order to get the appropriate types,
 * and to ensure that the file injecting the endpoints is loaded
 */
export const api = createApi({
  /**
   * A bare bones base query would just be `baseQuery: fetchBaseQuery({ baseUrl: '/' })`
   */
  baseQuery: baseQueryWithInterceptor,

  /**
   * Tag types must be defined in the original API definition
   * for any tags that would be provided by injected endpoints
   */
  tagTypes: ['Upload_status', 'PublicPosts', 'PrivatePosts', 'UserProfile'],
  /**
   * This api has endpoints injected in adjacent files,
   * which is why no endpoints are shown below.
   * If you want all endpoints defined in the same file, they could be included here instead
   */
  endpoints: () => ({}),
});
