import { LOCALE_CHANGED, offEvent, onEvent, tx } from '@transifex/native';
import { useLanguages } from '@transifex/react';
import { useRouter } from 'next/router';
import { useCallback, useEffect, useState } from 'react';
import { supportedLanguage, TransifexLanguage } from 'types';
import { isListEmpty } from 'utils/helper';

function useLanguage() {
  const languages = useLanguages();
  const [isLocalizationLoading, setIsLocalizationLoading] = useState(true);
  const [isUsersSelectedLanguageSupported, setIsUsersSelectedLanguageSupported] = useState(true);

  const {
    query: { displayLanguage },
  } = useRouter();

  const selectUsersLanguage = useCallback(
    (languages: TransifexLanguage[]) => {
      if (displayLanguage) return displayLanguage as string;

      const language = window.navigator.language;
      const languageCountryCode = language.split('-')[0];

      const isLanguageSupported = languages.some(
        (language) => language.code === languageCountryCode
      );
      setIsUsersSelectedLanguageSupported(isLanguageSupported);

      return isLanguageSupported ? languageCountryCode : supportedLanguage.EN;
    },
    [displayLanguage]
  );

  useEffect(() => {
    onEvent(LOCALE_CHANGED, () => setIsLocalizationLoading(false));

    return () => {
      offEvent(LOCALE_CHANGED, () => setIsLocalizationLoading(false));
    };
  }, [isUsersSelectedLanguageSupported, languages]);

  useEffect(() => {
    if (!isListEmpty(languages)) {
      const languageToDisplay = selectUsersLanguage(languages);
      tx.setCurrentLocale(languageToDisplay);
    }
  }, [displayLanguage, languages, selectUsersLanguage]);

  return {
    isLocalizationLoading,
  };
}

export default useLanguage;
