import { configureStore } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import { api } from './services/api';
import authReducer from './state/authSlice';

export const store = configureStore({
  reducer: {
    // 👇 Add the authReducer to the reducer object
    auth: authReducer,
    [api.reducerPath]: api.reducer,
  },
  // 👇 show the devTools only in development
  devTools: process.env.NODE_ENV !== 'production',
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({}).concat([api.middleware]),
});

export type AppDispatch = typeof store.dispatch;
export const useAppDispatch: () => AppDispatch = useDispatch;
export type RootState = ReturnType<typeof store.getState>;
